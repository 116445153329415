import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import UndoIcon from '@material-ui/icons/Undo';
import PaymentIcon from '@material-ui/icons/Payment';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import useOnClickOutside from '../../../common/useOnClickOutside';
import { useProfile } from '../../../../hooks/useProfile';

const Container = styled.div`
  width: 50px;
  position: relative;
  cursor: pointer;

  :hover,
  &.isClicked {
    color: #008b9b;
  }
`;

const OptionContainer = styled.div`
  background-color: #fff;
  display: block;
  width: 226px;
  position: absolute;
  top: 0px;
  right: 0px;
  visibility: hidden;
  z-index: -1;
  box-shadow: 3px 3px 3px 0px #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  &.isActive {
    z-index: 1;
    visibility: visible;
  }
`;

const Option = styled.a`
  display: flex;
  padding: 16px;
  background-color: inherit;
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
  div {
    color: rgba(0, 0, 0, 0.87);
    font-family: inherit;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.07px;
    line-height: 21.98px;
  }

  align-items: center;
  justify-content: space-between;
`;

const ButtonMore = ({ dealid }) => {
  const reference = useRef();
  const [isActive, setIsActive] = useState(false);
  const { profile } = useProfile();

  useOnClickOutside(reference, () => setIsActive(false));

  return (
    <Container ref={reference} onClick={() => setIsActive(true)}>
      <MoreVertIcon />
      <OptionContainer className={isActive ? 'isActive' : ''}>
        <Option target="_blank" href={`/mybunac/application#${dealid}`}>
          <div>Fullfill step</div>
          <CreateOutlinedIcon />
        </Option>
        {profile.role === 'agent' && (
          <>
            <Option href={`/mybunac/refund-recording#${dealid}`}>
              <div>Record refund</div>
              <UndoIcon />
            </Option>
            <Option href={`/mybunac/payment-recording#${dealid}`}>
              <div>Record payment</div>
              <PaymentIcon />
            </Option>
          </>
        )}
        {profile.role === 'supplier' && (
          <Option href={`/mybunac/payment#${dealid}`}>
            <div>Make a Payment</div>
            <PaymentIcon />
          </Option>
        )}
      </OptionContainer>
    </Container>
  );
};

export default ButtonMore;
