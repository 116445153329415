import React, { useState, useEffect } from 'react';
import Layout, { Content } from '../../components/Layout';
import Navigation from '../../components/mybunac/navigation';
import { useAuth } from '../../hooks/useAuth';
import Applications from '../../components/mybunac/Applications';
import styled from 'styled-components';
import { Login, NotVerified } from '../../components/login';
import Breadcrumbs from '../../components/mybunac/Breadcrumbs';
import DealOverview from '../../components/mybunac/DealOverview';
import { useProfile } from '../../hooks/useProfile';
const ContentLayout = styled.div`
  display: block;
  height: 100%;
`;

const MyBunac = () => {
  const { isAuthenticated, user } = useAuth();
  const { profile } = useProfile();
  const [breadcrumbLabel, setBreadCrumbLabel] = useState(null);

  useEffect(() => {
    if (!profile) {
      return;
    }
    let breadcrumbLabel = 'Deal Overview';
    if (profile.role === 'user') {
      breadcrumbLabel = 'Applications';
    }

    setBreadCrumbLabel(breadcrumbLabel);
  }, [profile]);

  const renderComponent = () => {
    if (profile.role === 'user') {
      return <Applications />;
    } else if (['supplier', 'agent'].includes(profile.role)) {
      return <DealOverview />;
    }
  };

  if (!isAuthenticated) {
    return <Login />;
  }

  if (!user.email_verified) {
    return <NotVerified />;
  }

  return (
    <Layout title={'MyBUNAC'}>
      <Navigation />
      <Content>
        <Breadcrumbs breadcrumbs={[{ label: breadcrumbLabel }]} />
        <ContentLayout>{renderComponent()}</ContentLayout>
      </Content>
    </Layout>
  );
};

export default MyBunac;