import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Link } from 'gatsby';

const NoApplication = () => (
  <Grid container spacing={8}>
    <Grid item xs={12}>
      Apply for a <Link to="/book-now">Programme</Link>
    </Grid>
  </Grid>
);

export default NoApplication;