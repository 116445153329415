import React, { useState, useEffect } from 'react';
import {
  Table as MaterialTable,
  TableBody,
  TableCell,
  TableRow,
  TablePagination,
} from '@material-ui/core';
import Loading from '../../../common/Loading';
import TableHead from './TableHead';
import ButtonMore from './ButtonMore';
import styled from 'styled-components';

const StyledTablePagination = styled(TablePagination)`
  margin-left: auto;
  margin-right: 0;
`;

const headConfig = [
  { id: 'date', label: 'Date', sortable: true },
  { id: 'name', label: 'Customer', sortable: true },
  { id: 'programme', label: 'Application', sortable: true },
  { id: 'stage', label: 'Status', sortable: true },
  { id: 'action', label: 'Action', type: 'more', sortable: false },
];

const Table = ({ rows, count, onChange, isLoading }) => {
  const [sortDirection, setSortDirection] = useState('asc');
  const [sortBy, setSortBy] = useState(headConfig[0].id);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isCurrentlyAsc = sortBy === property && sortDirection === 'asc';
    setSortDirection(isCurrentlyAsc ? 'desc' : 'asc');
    setSortBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    onChange({
      sortDirection,
      sortBy,
      rowsPerPage,
      page,
    });
  }, [sortDirection, sortBy, rowsPerPage, page]);

  return (
    <>
      <MaterialTable>
        <TableHead
          sortDirection={sortDirection}
          sortBy={sortBy}
          onRequestSort={handleRequestSort}
          rowCount={rows.length}
          headConfig={headConfig}
        />
        <TableBody>
          {!!isLoading && (
            <TableRow>
              <TableCell align="center" colSpan={headConfig.length}>
                <Loading text="Loading deals" />
              </TableCell>
            </TableRow>
          )}
          {!isLoading &&
            rows.map((row, index) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={`${row.name}${index}`}
                >
                  {headConfig.map((config, index) => {
                    if (config.type === 'more') {
                      return (
                        <TableCell key={index}>
                          <ButtonMore dealid={row.id} />
                        </TableCell>
                      );
                    }
                    return <TableCell key={index}>{row[config.id]}</TableCell>;
                  })}
                </TableRow>
              );
            })}
        </TableBody>
      </MaterialTable>
      <StyledTablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={count} // @TODO receive count from backend
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
};

export default Table;
