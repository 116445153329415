import React, { useState, useEffect } from 'react';
import { Headline } from '../../common/Headline';
import Grid from '@material-ui/core/Grid';
import SearchInput from './searchinput';
import Table from './Table';
import styled from 'styled-components';
import { useDeals } from '../../../hooks/useDeals';
import { useProfile } from '../../../hooks/useProfile';
import { formatDate } from '../../../lib/formatter';

const Spacer = styled.div`
  height: 20px;
`;
const ContentContainer = styled.div`
  padding: 0 20px;
`;

const DealOverview = () => {
  const [rows, setRows] = useState([]);
  const { profile } = useProfile();
  const { deals, fetchDeals, count, setFetchOptions, isLoading } = useDeals();

  const createRows = () => {
    const rows = [];
    deals.forEach(deal => {
      rows.push({
        id: deal.id,
        date: formatDate(new Date(deal.add_time)),
        name: deal.person_id.name,
        programme: deal.programmeDetails.title,
        stage: deal.currentStage.label,
      });
    });

    setRows(rows);
  };

  const onTableChanged = async ({
    page,
    rowsPerPage,
    sortDirection,
    sortBy,
  }) => {
    setFetchOptions({ page, rowsPerPage, sortDirection, sortBy });
    if (profile && profile.role === 'supplier') {
      fetchDeals(); // only fetch deals automatically as supplier when table options change
    }
  };

  const setSearchText = searchText => {
    if (profile) {
      switch (profile.role) {
        case 'supplier':
          setFetchOptions({ filterQuery: searchText });
          break;
        case 'agent':
          setFetchOptions({ dealId: searchText });
          break;
        default:
      }
    }
  };

  useEffect(() => {
    if (deals) {
      createRows();
    }
  }, [deals]);

  useEffect(() => {
    if (profile && profile.role === 'supplier') {
      fetchDeals();
    }
  }, [profile]);

  return (
    <Grid container direction="column" justify="space-between">
      <Headline>Deal Overview</Headline>
      <ContentContainer>
        <SearchInput onChange={setSearchText} onSearch={fetchDeals} />
        <Spacer />
        <Table
          count={count}
          rows={rows}
          onChange={onTableChanged}
          isLoading={isLoading}
        />
      </ContentContainer>
    </Grid>
  );
};

export default DealOverview;
export { DealOverview };
