import React from 'react';
import styled from 'styled-components';
import SearchIcon from '@material-ui/icons/Search';

const Input = styled.input`
  display: block;
  height: 40px;
  width: 100%;
  box-sizing: border-box;
  outline: none;
  padding: 8px 20px;
  font-family: Arial;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  background-color: #ffffff;
  color: #30393f;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.3);
  border: none;
  padding-right: 60px;
`;

const SearchWrapper = styled.div`
  position: relative;
  flex: 1;
`;

const SearchIconContainer = styled.div`
  height: 40px;
  width: 40px;
  background-color: #0094a5;
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
`;

const SearchInput = ({ onChange, onSearch }) => {
  const handleOnEnter = event => {
    if (event.key === 'Enter') {
      onSearch(event.target.value);
    }
  };
  return (
    <SearchWrapper>
      <SearchIconContainer onClick={onSearch}>
        <SearchIcon />
      </SearchIconContainer>
      <Input
        onKeyDown={handleOnEnter}
        onChange={event => onChange(event.target.value)}
      />
    </SearchWrapper>
  );
};

export default SearchInput;
