import { useEffect } from 'react';

// Hook https://usehooks.com/useOnClickOutside/
const useOnClickOutside = (reference, handler) => {
  useEffect(
    () => {
      const listener = event => {
        // Do nothing if clicking reference's element or descendent elements

        if (!reference.current || reference.current.contains(event.target)) {
          return;
        }
        handler(event);
      };

      document.addEventListener('mouseup', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mouseup', listener);
        document.removeEventListener('touchstart', listener);
      };
    },
    // Add reference and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [reference, handler]
  );
};

export default useOnClickOutside;
