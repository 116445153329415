import React from 'react';
import {
  TableCell,
  TableHead as MaterialTableHead,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import styled from 'styled-components';

const StyledSortLabel = styled(TableSortLabel)`
  color: white !important;
  > * {
    color: white !important;
  }
`;

const TableHead = ({
  headConfig,
  classes,
  sortDirection,
  sortBy,
  numSelected,
  rowCount,
  onRequestSort,
}) => {
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <MaterialTableHead>
      <TableRow>
        {headConfig.map(headConfig => {
          return (
            <TableCell
              key={headConfig.id}
              align="left"
              sortDirection={sortBy === headConfig.id ? sortDirection : false}
            >
              {headConfig.sortable === true && (
                <StyledSortLabel
                  active={sortBy === headConfig.id}
                  direction={sortBy === headConfig.id ? sortDirection : 'asc'}
                  onClick={createSortHandler(headConfig.id)}
                >
                  {headConfig.label}
                </StyledSortLabel>
              )}

              {headConfig.sortable === false && <div>{headConfig.label}</div>}
            </TableCell>
          );
        })}
      </TableRow>
    </MaterialTableHead>
  );
};

export default TableHead;
